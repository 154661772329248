import Vue from 'vue';
import VueAnalytics from 'vue-ua';
import App from './App';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  appName: 'tolastarras',
  appVersion: '1',
  trackingId: 'UA-131115781-1',
});

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
