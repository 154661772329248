<template>
  <v-container>
    <v-row>
      <v-col class="text-center">
        <h1 class="text-uppercase blue--text">
          zenwhitenoise.com
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Raleway:200,100,400');

.container {
  font-family: 'Raleway', sans-serif;
  margin: 0 auto;

  h1 {
    letter-spacing: 10px;
    font-size: 3.8rem;

    > span {
      margin-left: -1.4rem;
    }
  }
}

@media screen and (max-width: 640px) {
  h1 {
    letter-spacing: 5px !important;
    font-size: 2rem !important;

    > span {
      margin-left: -1rem !important;
    }
  }
}

@media screen and (max-width: 360px) {
  h1 {
    font-size: 1.4rem !important;

    > span {
      margin-left: -0.6rem !important;
    }
  }
}
</style>
